<template>
    <div class="admin_page_body" v-loading="isRequestLoading">
        <el-form ref="form" :inline="true" :model="form" size="small" label-position="top">
            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">基本信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="标题">
                        <span class="text">{{infoData.title}}</span>
                    </el-form-item>

                    <el-form-item label="采购单号">
                        <span class="text">{{infoData.code}}</span>
                    </el-form-item>

                    <el-form-item label="发布时间">
                        <span class="text">{{infoData.createdAt}}</span>
                    </el-form-item>

                    <el-form-item label="截止时间">
                        <span class="text">{{infoData.stopAt}}</span>
                    </el-form-item>

                    <el-form-item label="采购单状态">
                        <span class="status green" v-if="infoData.isRun === 1">进行中</span>
                        <span class="status red" v-if="infoData.isRun === 2">已关闭</span>
                        <span class="status info" v-if="infoData.isRun === 3">已过期</span>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">采购商品</h1>
                <div class="data_detail mt24">
                    <el-form-item label="商品名称">
                        <span class="text">{{infoData.goodName}}</span>
                    </el-form-item>

                    <el-form-item label="规格">
                        <span class="text">{{infoData.sku}}</span>
                    </el-form-item>

                    <el-form-item label="采购数量">
                        <span class="text">{{infoData.num}}{{infoData.unitName}}</span>
                    </el-form-item>

                    <el-form-item label="采购价格">
                        <span class="text">￥ {{infoData.price}} （{{getPriceStatus(infoData.priceStatus)}}）</span>
                    </el-form-item>

                    <el-form-item label="商品图片" class="full">
                        <el-image fit="contain" v-if="infoData.imageUrl"
                                  style="width: 100px; height: 100px"
                                  :src="imageURL + infoData.imageUrl"
                                  :preview-src-list="[imageURL + infoData.imageUrl]">
                        </el-image>
                    </el-form-item>

                    <el-form-item label="补充说明" class="full">
                        <span class="text">{{infoData.content}}</span>
                    </el-form-item>

                    <el-form-item label="驳回原因" class="full" v-if="infoData.auditStatus === 4">
                        <span class="text">{{infoData.reason}}</span>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18" v-if="infoData.status === 0 || (infoData.status === 1 && infoData.isRun === 3)">
                <h1 class="title">供货商报价列表</h1>
                <div class="data_detail mt24">
                    <el-table :data="quotedPrices" style="width: 100%">
                        <el-table-column prop="title" label="报价标题" min-width="120px"></el-table-column>
                        <el-table-column prop="companyName" label="公司名称" min-width="120px"></el-table-column>
                        <el-table-column label="报价方" min-width="120px" align="center">
                            <template slot-scope="scope">
                                联系人：{{scope.row.contactName}}<br />
                                联系电话：{{scope.row.contactPhone}}
                            </template>
                        </el-table-column>
                        <el-table-column label="商品图片" min-width="100px" align="center">
                            <template slot-scope="scope">
                                <el-image
                                        v-if="scope.row.imageUrl"
                                        style="width: 80px; height: 80px"
                                        :src="imageURL + scope.row.imageUrl"
                                        :preview-src-list="[imageURL + scope.row.imageUrl]">
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column label="价格说明" min-width="100px" align="center">
                            <template slot-scope="scope">
                                ￥{{scope.row.price}}（{{getPriceStatus(scope.row.priceStatus)}}）
                            </template>
                        </el-table-column>
                        <el-table-column label="数量" min-width="80px" align="center">
                            <template slot-scope="scope">
                                {{scope.row.num}}{{scope.row.unitName}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="content" label="报价说明" min-width="120px"></el-table-column>
                        <el-table-column prop="createdAt" label="报价日期" min-width="100px" align="center"></el-table-column>
                        <el-table-column prop="deliveryAt" label="预计交货日期" min-width="100px"></el-table-column>
                    </el-table>
                </div>
            </div>

            <div class="page_footer mt24">
                <el-button type="primary" size="small" @click="$router.go(-1)">返回，采购管理</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
  import {imageURL} from "../../../../utils/config";
  import {getBuyingInfo, getBuyingRead} from "../../../../api/admin/supplyBuying";

  export default {
    name: "supplyBuyingBuyingAdd",
    data () {
      return {
        isRequestLoading: false,
        form: {},
        imageURL: imageURL,
        infoData: {},
        quotedPrices: []
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getBuyingInfo()
    },
    methods: {
      getBuyingInfo () {
        this.isRequestLoading = true;
        getBuyingInfo(this.$route.query.id).then(res => {
          this.isRequestLoading = false;
          if (res.code === 0) {
            this.infoData = res.data.purchase;
            this.quotedPrices = res.data.quotedPrices;
            this.getBuyingLength()
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getPriceStatus (status) {
        switch (status) {
          case 1:
            return '可议价';
          case 2:
            return '不可议价';
          case 3:
            return '面议';
        }
      },
      getBuyingLength () {
        getBuyingRead().then(res => {
          if (res.code === 0) {
            this.$store.commit('changeBuyingLength', res.data)
          }
        })
      }
    },
    components: {}
  }
</script>
